import { useTranslation } from "@pancakeswap/localization"
import { Flex, Text, useToast } from "@pancakeswap/uikit"
import { ToastDescriptionWithTx } from "components/Toast"
import contracts from "config/constants/contracts"
import { getStocksConfig } from "config/constants/stocks"
import { Contract } from "ethers"
import { useCallWithMarketGasPrice } from "hooks/useCallWithMarketGasPrice"
import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { AppDispatch } from "state"
import { fetchDataStocks } from "state/home/actions"
import { getDataStocks } from "state/home/fetchDataStocks"
import { getAddress } from "utils/addressHelpers"

export const usePausing = (contract: Contract|null, account: string, chainId: number) => {
  const { toastSuccess, toastError } = useToast()
  const { callWithMarketGasPrice } = useCallWithMarketGasPrice()
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>();
  const [pendingTx, setPendingTx] = useState(false)
  const handlePausing = useCallback(async (alertText: string, stockSymbol:string, callFunction:string) => {
    setPendingTx(true)
    try {
      const tx = await callWithMarketGasPrice(contract, callFunction, [])
      const receipt = await tx.wait()
      if (receipt.status) {
        toastSuccess(
          alertText,
          <Flex flexDirection="column" justifyContent="flex-start">
            <Text><span style={{ color: "#777E90", fontWeight: 700 }}>Mã cổ phiếu: </span>{stockSymbol}</Text>
            <ToastDescriptionWithTx txHash={receipt.transactionHash} />
          </Flex>
        )     
      } 
    } catch (e) {
      console.error(e)
      toastError(t('Thất bại!'), t('Đã xảy ra lỗi, vui lòng thử lại!'))
      setPendingTx(false)
    } finally {
      const config = await getStocksConfig(chainId)
      const makertplaceAddress = getAddress(contracts.marketplace, chainId)
      const dataStocks = await getDataStocks(config, chainId, account, makertplaceAddress)
      dispatch(fetchDataStocks(dataStocks))
      setPendingTx(false)
    }
  }, [callWithMarketGasPrice, contract, chainId, toastSuccess, account, t, toastError])

  return { handlePausing, pendingTx }
}
