import { AutoRenewIcon, Button, CopyIcon, Flex, IconButtonV2, PencilIcon, Text, useMatchBreakpoints } from "@pancakeswap/uikit";
import Link from "next/link";
import React, { useState } from "react";
import { StockItem } from "state/home/type";
import { formatNumber } from "utils/formatNumber";
import TextWithTooltip from "../components/TextWithTooltip";
import TextWithTooltipV2 from "../components/TextWithTooltipV2";
import { ContainerLink, ContainerRow, Mask, Tooltip } from "../components/style";
import { usePausing } from "./hook/usePausing";
import { useStocks } from "hooks/useContract";

interface Props {
    item: StockItem,
    account: string,
    chainId: number,
}

const Row: React.FC<Props> = ({
    item, account, chainId
}) => {
    const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false);
    const { isMobile, isDesktop } = useMatchBreakpoints()
    const renderAddress = (address: string) => {
        return `${address?.substring(0, 4)}...${address?.substring(address?.length - 4)}`
    }

    const handleCoppy = (address: string) => {
        if (navigator.clipboard && navigator.permissions) {
            navigator.clipboard.writeText(address).then(() => displayTooltipCode())
        } else if (document.queryCommandSupported('copy')) {
            const ele = document.createElement('textarea')
            ele.value = address
            document.body.appendChild(ele)
            ele.select()
            document.execCommand('copy')
            document.body.removeChild(ele)
            displayTooltipCode()
        }
    }

    function displayTooltipCode() {
        setIsTooltipDisplayed(true)
        setTimeout(() => {
            setIsTooltipDisplayed(false)
        }, 3000)
    }
    const contract = useStocks(item?.contract?.address)
    const { handlePausing, pendingTx } = usePausing(contract, account, chainId)
    const renderNameCompany = (name: string) => {
        if (name?.length > 84) {
            return `${name?.substring(0, 84)}...`
        }
        return name
    }

    return (
        <ContainerLink>
            <Link href={`/shareholders/${item?.contract?.address}`}>
                <Mask />
            </Link>
            <ContainerRow isHeader={false} style={{ position: "absolute" }}>
                <Flex width="100%" justifyContent="space-between" alignItems="center" >
                    <Text display={["block", , , "none"]} bold>Mã cổ phiếu</Text>
                    <Text color={isMobile ? "text" : "textSubtle"} bold={!isMobile}>{item?.symbol}</Text>
                </Flex>
                {
                    !isMobile ?
                        <Flex width="100%" justifyContent="space-between" alignItems={["flex-start", , "center"]} flexDirection={["column", , "row"]} >
                            <Text display={["block", , , "none"]} bold>Tên công ty</Text>
                            {item?.companyName.length > 84 ?
                                <TextWithTooltipV2
                                    title={<Text maxWidth={["100%", , , "100%"]} textAlign={["justify", , , "left"]} paddingRight={["0px", , , "12px"]} width="100%" style={{ wordBreak: "break-word" }} color={isMobile ? "text" : "textSubtle"} bold={!isMobile}>{renderNameCompany(item?.companyName)}</Text>}
                                    tooltipText={item?.companyName}
                                />
                                :
                                <Text marginTop="0.5rem" maxWidth={["100%", , , "100%"]} textAlign={["justify", , , "left"]} paddingRight={["0px", , , "12px"]} width="100%" style={{ wordBreak: "break-word" }} color={isMobile ? "text" : "textSubtle"} bold={!isMobile}>{item?.companyName}</Text>
                            }

                        </Flex>
                        :
                        item?.companyName.length > 30 ?
                            <Flex width="100%" justifyContent="space-between" alignItems={["flex-start", , "center"]} flexDirection="column">
                                <Text display={["block", , , "none"]} bold>Tên công ty</Text>
                                <Text textAlign={["justify", , , "left"]} paddingRight={["0px", , , "12px"]} style={{ wordBreak: "break-word" }} color={isMobile ? "text" : "textSubtle"} bold={!isMobile}>{item?.companyName}</Text>
                            </Flex>
                            :
                            <Flex width="100%" justifyContent="space-between" alignItems={["flex-start", , "center"]}>
                                <Text display={["block", , , "none"]} bold>Tên công ty</Text>
                                <Text textAlign={["justify", , , "left"]} paddingRight={["0px", , , "12px"]} style={{ wordBreak: "break-word" }} color={isMobile ? "text" : "textSubtle"} bold={!isMobile}>{item?.companyName}</Text>
                            </Flex>
                }
                <Flex width="100%" justifyContent={["space-between", , "flex-end"]} alignItems="center" paddingRight={["0rem", , "2rem"]} >
                    <Text display={["block", , , "none"]} bold>Mệnh giá</Text>
                    <Text color={isMobile ? "text" : "textSubtle"} bold={!isMobile}>{formatNumber(item?.issuePrice)}</Text>
                </Flex>
                <Flex width="100%" paddingRight={["0px", , , "0.5rem"]} alignItems="center" justifyContent={["space-between", , "center"]}  >
                    <Text display={["block", , , "none"]} bold >Địa chỉ contract</Text>
                    <Flex alignItems="center" style={{ gap: "12px" }} position="relative" zIndex={20}>
                        <Text width="100%" textAlign={["right", , , "left"]} color={isMobile ? "text" : "textSubtle"} bold={!isMobile}>{renderAddress(item?.contract?.address)}</Text>
                        <CopyIcon style={{ cursor: "pointer" }} onClick={() => handleCoppy(item?.contract?.address)} />
                        <Tooltip isTooltipDisplayed={isTooltipDisplayed}>Copied</Tooltip>
                    </Flex>
                </Flex>
                <Flex width="100%" justifyContent={["space-between", , "flex-end"]} alignItems="center" paddingRight={["0rem", , "2rem"]} >
                    <Flex style={{ display: isDesktop ? "none" : "block" }} zIndex={20}>
                        <TextWithTooltip
                            title="TSLPH"
                            tooltipText="Tổng số lượng phát hành"
                        />
                    </Flex>
                    <Text color={isMobile ? "text" : "textSubtle"} bold={!isMobile}>{formatNumber(item?.totalReleaseStocks)}</Text>
                </Flex>
                <Flex width="100%" justifyContent={["space-between", , "flex-end"]} alignItems="center" paddingRight={["0rem", , "2rem"]}>
                    <Flex style={{ display: isDesktop ? "none" : "block" }} zIndex={20}>
                        <TextWithTooltip
                            title="TGTCP"
                            tooltipText="Tổng giá trị cổ phiếu"
                        />
                    </Flex>
                    <Text color={isMobile ? "text" : "textSubtle"} bold={!isMobile}>{formatNumber(item?.totalValueOfStocks)}</Text>
                </Flex>
                <Flex width="100%" zIndex={20} alignItems="center" flexDirection="column" justifyContent="space-between"   >
                    <Flex width="100%" justifyContent={["space-between", , , "space-between"]} alignItems="center" style={{ gap: "1rem" }}>
                        {!item?.isAdmin ?
                            <IconButtonV2 scale="sm" disabled={!item?.isAdmin}>
                                <PencilIcon />
                            </IconButtonV2>
                            :
                            <IconButtonV2 scale="sm">
                                <Link href={`/edit/${item?.contract.address}`}>
                                    <PencilIcon />
                                </Link>
                            </IconButtonV2>
                        }

                        {(!item?.isAdmin) ?
                            (
                                item?.isPause ?
                                    <TextWithTooltipV2
                                        title={
                                            <Flex width="100%" alignItems="center">
                                                <Button
                                                    paddingX="8px"
                                                    height="40px"
                                                    width={["100%", , , "100%"]}
                                                    disabled={!item?.isAdmin || pendingTx}
                                                    onClick={() => handlePausing("Tiếp tục giao dịch thành công!", item?.contract?.symbol, "continueTrading")}
                                                    endIcon={pendingTx ? <AutoRenewIcon spin color='#9395A4' /> : ""}
                                                >
                                                    Tiếp tục giao dịch
                                                </Button>
                                            </Flex>
                                        }
                                        tooltipText={<Text color="red">Bạn không phải chủ sở hữu cổ phiếu</Text>}
                                    />
                                    :
                                    <TextWithTooltipV2
                                        title={
                                            <Flex width="100%" alignItems="center">
                                                <Button
                                                    paddingX="8px"
                                                    variant="danger"
                                                    height="40px"
                                                    width={["100%", , , "100%"]}
                                                    disabled={!item?.isAdmin || pendingTx}

                                                    onClick={() => handlePausing("Ngừng giao dịch thành công!", item?.contract?.symbol, "pauseTrading")}
                                                    endIcon={pendingTx ? <AutoRenewIcon spin color='#9395A4' /> : ""}
                                                >
                                                    Ngừng giao dịch
                                                </Button>
                                            </Flex>
                                        }
                                        tooltipText={<Text color="red">Bạn không phải chủ sở hữu cổ phiếu</Text>}
                                    />
                            )
                            :
                            item?.isPause ?
                                <Flex width="100%" alignItems="center">
                                    <Button
                                        paddingX="8px"
                                        height="40px"
                                        width={["100%", , , "100%"]}
                                        disabled={!item?.isAdmin || pendingTx}
                                        onClick={() => handlePausing("Tiếp tục giao dịch thành công!", item?.contract?.symbol, "continueTrading")}
                                        endIcon={pendingTx ? <AutoRenewIcon spin color='#9395A4' /> : ""}
                                    >
                                        Tiếp tục giao dịch
                                    </Button>
                                </Flex>
                                :
                                <Flex width="100%" alignItems="center">
                                    <Button
                                        paddingX="8px"
                                        variant="danger"
                                        height="40px"
                                        width={["100%", , , "100%"]}
                                        disabled={!item?.isAdmin || pendingTx}
                                        style={{ backgroundColor: "#EF1616" }}
                                        onClick={() => handlePausing("Ngừng giao dịch thành công!", item?.contract?.symbol, "pauseTrading")}
                                        endIcon={pendingTx ? <AutoRenewIcon spin color='#9395A4' /> : ""}
                                    >
                                        Ngừng giao dịch
                                    </Button>
                                </Flex>
                        }
                    </Flex>
                </Flex>
            </ContainerRow>
        </ContainerLink>
    )
}
export default Row