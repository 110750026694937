import { BoxProps, ChevronLeftIcon, Flex, Text } from '@pancakeswap/uikit'
import useTheme from 'hooks/useTheme'
import { ReactNode, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

const DropDownHeader = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px;
    transition: border-radius 0.15s;
`

const DropDownListContainer = styled.div`
    width: 100%;
    height: 100%;
    max-height: 250px;
    padding: 12px;
    position: absolute;
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: ${({ theme }) => theme.zIndices.dropdown};
    transition: transform 0.15s, opacity 0.15s;
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: 0px 4px 18px 0px rgba(75, 75, 75, 0.08);
`

const DropDownContainer = styled.div<{ isOpen: boolean; width: number; height: number; isActiveBorder?: boolean }>`
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    background: ${({ theme }) => theme.colors.background};
    box-shadow: 0px 4px 18px 0px rgba(75, 75, 75, 0.08);
    border-radius: 12px;
    border: 2px solid
        ${({ isActiveBorder, theme }) => (isActiveBorder ? theme.colors.primary : theme.colors.cardBorder)};
    ${(props) =>
        props.isOpen &&
        css`
            ${DropDownListContainer} {
                height: auto;
                transform: scaleY(1);
                opacity: 1;
                border-top-width: 2;
                box-shadow: 0px 4px 18px 0px rgba(75, 75, 75, 0.08);
                width: 100%;
                background: ${({ theme }) => theme.colors.background};
                top: 49px;
            }
        `}
`

const DropDownList = styled.ul`
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    z-index: ${({ theme }) => theme.zIndices.dropdown};
`

const ListItem = styled.li`
    list-style: none;
    padding: 8px 16px;
    border-radius: 0px;
    &:hover {
        > div {
            color: ${({ theme }) => theme.colors.primary};
            font-weight: bold;
        }
    }
`

export interface SelectProps extends BoxProps {
    options: OptionProps[]
    onOptionChange?: (option: OptionProps) => void
    placeHolderText?: string
    defaultOptionIndex?: number
    startIcon?: ReactNode
    isActiveBorder?: boolean
}

export interface OptionProps {
    label: string
    value: any
}

const Select: React.FunctionComponent<React.PropsWithChildren<SelectProps>> = ({
    options,
    onOptionChange,
    defaultOptionIndex,
    placeHolderText,
    startIcon,
    isActiveBorder,
    ...props
}) => {
    const containerRef = useRef(null)
    const dropdownRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const [optionSelected, setOptionSelected] = useState(false)
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(defaultOptionIndex)
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })

    const { theme } = useTheme()

    const toggling = (event: React.MouseEvent<HTMLDivElement>) => {
        if (options.length > 1) {
            setIsOpen(!isOpen)
        }
        event.stopPropagation()
    }

    const onOptionClicked = (selectedIndex: number) => () => {
        setSelectedOptionIndex(selectedIndex)
        setIsOpen(false)
        setOptionSelected(true)

        if (onOptionChange) {
            onOptionChange(options[selectedIndex])
        }
    }

    useEffect(() => {
        setContainerSize({
            width: dropdownRef.current.offsetWidth, // Consider border
            height: dropdownRef.current.offsetHeight,
        })

        const handleClickOutside = () => {
            setIsOpen(false)
        }

        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        if (defaultOptionIndex) {
            // setSelectedOptionIndex(defaultOptionIndex - 1)
            setOptionSelected(true)
        }
    }, [defaultOptionIndex])

    return (
        <DropDownContainer
            isOpen={isOpen}
            {...props}
            ref={containerRef}
            {...containerSize}
            isActiveBorder={isActiveBorder}
        >
            {containerSize.width !== 0 && (
                <DropDownHeader onClick={toggling}>
                    <Flex width="100%" alignItems="center">
                        {startIcon && <Flex width="auto">{startIcon}</Flex>}
                        <Text
                            ml="8px"
                            fontSize={[, , '14px', '16px']}
                            color={!optionSelected && placeHolderText ? 'text' : undefined}
                        >
                            {!optionSelected && placeHolderText ? placeHolderText : options[selectedOptionIndex]?.label}
                        </Text>
                    </Flex>
                    <ChevronLeftIcon
                        color="text"
                        onClick={toggling}
                        style={{ transform: isOpen ? 'rotate(90deg)' : 'rotate(270deg)' }}
                    />
                </DropDownHeader>
            )}
            <DropDownListContainer>
                <DropDownList ref={dropdownRef}>
                    {options.map((option, index) =>
                        placeHolderText || index !== selectedOptionIndex ? (
                            <CustomListItem onClick={onOptionClicked(index)} key={option.label}>
                                <Text
                                    fontSize="16px"
                                    bold={selectedOptionIndex === index ? !false : false}
                                    color={selectedOptionIndex === index ? 'primary' : 'text'}
                                >
                                    {option.label}
                                </Text>
                            </CustomListItem>
                        ) : null,
                    )}
                </DropDownList>
            </DropDownListContainer>
        </DropDownContainer>
    )
}

export default Select

const CustomListItem = styled(ListItem)`
    @media screen and (max-width: 600px) {
        padding: 6px 0px;
    }
`
