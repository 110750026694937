import { Button, CheckboxV2, DownloadIcon, Flex, Input, InputGroup, SearchIcon, Text, useMatchBreakpoints } from "@pancakeswap/uikit";
import Select, { OptionProps } from "components/Select/Select";
import React, { useMemo } from "react";
import { CSVLink } from "react-csv";
import { useAppDispatch } from "state";
import { TradeType } from "state/home";
import { fetchTradingType } from "state/home/actions";
import { StockItem } from "state/home/type";
import { fetchNumberPageCurrent } from "state/home/actions";
import { formatNumber } from "utils/formatNumber";
import { renderDate } from "utils/renderDate";

interface Props {
    tradingType: number
    handleChangeQuery: (newQuery: string) => void
    searchQuery: string,
    manegeTempConfig: StockItem[]
}

const Filter: React.FC<Props> = ({
    tradingType, handleChangeQuery, searchQuery, manegeTempConfig
}) => {
    const { isTablet, isDesktop, isMobile } = useMatchBreakpoints()
    const dispatch = useAppDispatch()
    const handleChangeSelect = (newSelected: OptionProps) => {
        dispatch(fetchTradingType({ tradingType: newSelected.value }))
    }

    const handleChangeSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
        const re = /[0-9a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s\W|_]+$/
        if (event.target.value === '' || re.test(event.target.value)) {
            const values = event.target.value
            const replace = values.replace(/[.><,'":;/*+?^${}()|[\]\\!@#%&_~]/g, "");
            handleChangeQuery(replace)
        }
    }

    const headers = [
        { label: "STT", key: "index" },
        { label: "Mã cổ phiếu", key: "symbol" },
        { label: "Tên công ty", key: "companyName" },
        { label: "Mệnh giá", key: "issuePrice" },
        { label: "Địa chỉ ví phê duyệt", key: "approveWallet" },
        { label: "Tổng số lượng phát hành", key: "totalReleaseStocks" },
        { label: "Tổng giá trị cổ phiếu", key: "totalValueOfStocks" },
    ]
    const dataCsv = useMemo(() => {
        return manegeTempConfig?.map((dt, index) => {
            return {
                index: index + 1,
                symbol: dt?.symbol,
                companyName: dt?.companyName,
                issuePrice: formatNumber(dt?.issuePrice),
                approveWallet: dt?.approveWallet,
                totalReleaseStocks: formatNumber(dt?.totalReleaseStocks),
                totalValueOfStocks: formatNumber(dt?.totalValueOfStocks)
            }
        })

    }, [manegeTempConfig])

    return (
        <Flex
            width="100%"
            justifyContent="space-between"
            mt="2rem"
            alignItems="flex-end"
            flexWrap="wrap"
            style={{ gap: "1rem" }}
        >
            {isDesktop &&
                <Flex alignItems="center" style={{ gap: "1rem" }}>
                    <Flex alignItems="center" style={{ gap: "1rem" }}>
                        <CheckboxV2 checked={tradingType === TradeType.ALL} scale="sm" onClick={() => { dispatch(fetchTradingType({ tradingType: TradeType.ALL })); dispatch(fetchNumberPageCurrent({ pageActiveManage: 0 })) }} />
                        <Text fontSize="18px" bold>Tất cả</Text>
                    </Flex>
                    <Flex alignItems="center" style={{ gap: "1rem" }}>
                        <CheckboxV2 checked={tradingType === TradeType.STOPTRANSACTION} scale="sm" onClick={() => {dispatch(fetchTradingType({ tradingType: TradeType.STOPTRANSACTION }));dispatch(fetchNumberPageCurrent({ pageActiveManage: 0 }))}} />
                        <Text fontSize="18px" bold>Ngừng giao dịch</Text>
                    </Flex>
                    <Flex alignItems="center" style={{ gap: "1rem" }}>
                        <CheckboxV2 checked={tradingType === TradeType.TRADING} scale="sm" onClick={() => {dispatch(fetchTradingType({ tradingType: TradeType.TRADING }));dispatch(fetchNumberPageCurrent({ pageActiveManage: 0 }))}} />
                        <Text fontSize="18px" bold>Đang giao dịch</Text>
                    </Flex>
                </Flex>
            }
            {(isTablet || isMobile) &&
                <Flex width={["100%", , "auto"]} flexDirection="column" style={{ gap: "12px" }}>
                    <Text color="textDisabled" textTransform="uppercase">Trạng thái</Text>
                    <Flex
                        width={["100%", , "300px"]}
                    >
                        <Select
                            options={[
                                {
                                    label: "Tất cả",
                                    value: TradeType.ALL
                                },
                                {
                                    label: "Ngừng giao dịch",
                                    value: TradeType.STOPTRANSACTION
                                },
                                {
                                    label: "Đang giao dịch",
                                    value: TradeType.TRADING
                                }
                            ]}
                            defaultOptionIndex={0}
                            onOptionChange={handleChangeSelect}
                        />
                    </Flex>
                </Flex>

            }
            <Flex alignItems="center" width={["100%", , "auto"]} style={{ gap: "1rem" }}>
                {isDesktop &&
                    <CSVLink
                        data={dataCsv}
                        headers={headers}
                        filename={`Quanly_${renderDate()}.csv`}
                        className="btn btn-primary"
                        target="_blank"
                        separator={","}
                    >
                        <Button
                            width={["100%", , "auto"]}
                            endIcon={<DownloadIcon />}
                        >
                            Tải xuống
                        </Button>
                    </CSVLink>
                }
                <Flex
                    width={["100%", , "250px"]}
                >
                    <InputGroup
                        endIcon={<SearchIcon color="text90" />}
                    >
                        <Input
                            value={searchQuery}
                            placeholder="Tìm kiếm theo mã cổ phiếu"
                            onChange={(evt) => handleChangeSearchQuery(evt)}
                        />
                    </InputGroup>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Filter