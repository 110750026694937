import { Flex, Image, Text, useMatchBreakpoints } from "@pancakeswap/uikit";
import { Paginate } from "components/Paginate";
import { ITEMSPERPAGE } from "config";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { TradeType } from "state/home";
import { fetchNumberPageCurrent } from "state/home/actions";
import { StockItem } from "state/home/type";
import TextWithTooltip from "../components/TextWithTooltip";
import { ContainerRow } from "../components/style";
import Filter from "./Filter";
import Row from "./Row";
import WrappedCard from "./WrappedCard";

interface Props {
    tradingType: number,
    isLoading: boolean,
    dataStocks: StockItem[],
    pageActiveManage: number,
    chainId: number,
    account: string,
}

const Manage: React.FC<Props> = ({
    tradingType,
    isLoading,
    dataStocks,
    pageActiveManage,
    chainId,
    account,
}) => {
    const [searchQuery, setSearchQuery] = useState("")
    const { isTablet, isMobile } = useMatchBreakpoints()
    const [currentItems, setCurrentItems] = useState([...dataStocks])
    const [pageCount, setPageCount] = useState(0)
    const dispatch = useDispatch<AppDispatch>()
    
    const filterData = useMemo(() => {
        switch (tradingType) {
            case TradeType.ALL: {
                return [...dataStocks]
            }
            case TradeType.STOPTRANSACTION: {
                return [...dataStocks.filter((dt) => dt?.isPause)]
            }
            case TradeType.TRADING: {
                return [...dataStocks.filter((dt) => !dt?.isPause)]
            }
            default: {
                return [...dataStocks]
            }
        }
    }, [dataStocks, tradingType, account])

    const filterSearchData = useMemo(() => {
        if (searchQuery?.length) {
            return [...filterData.filter((dt) => dt?.symbol.toLowerCase().includes(searchQuery.toLowerCase()))]
        }
        return [...filterData]
    }, [searchQuery, filterData, account])

    const handlePageClick = (event) => {
        dispatch(fetchNumberPageCurrent({ pageActiveManage: event.selected }))
    }

    useEffect(() => {
        const item = (pageActiveManage * ITEMSPERPAGE) % filterSearchData.length
        const endOffset = item + ITEMSPERPAGE
        setCurrentItems(filterSearchData.slice(item, endOffset))
        setPageCount(Math.ceil(filterSearchData.length / ITEMSPERPAGE))

    }, [pageActiveManage, ITEMSPERPAGE, filterSearchData, account])


    return (
        <Flex width="100%" height="100%" flexDirection="column" style={{ gap: "2rem" }}>
            <WrappedCard data={dataStocks} isLoading={isLoading} />
            <Filter
                searchQuery={searchQuery}
                tradingType={tradingType}
                handleChangeQuery={(newQuery: string) => { setSearchQuery(newQuery); dispatch(fetchNumberPageCurrent({ pageActiveManage: 0 })) }}
                manegeTempConfig={filterSearchData}
            />
            <Flex
                width="100%"
                flexWrap="wrap"
                height="auto"
                style={{ gap: (isTablet || isMobile) ? "1rem" : "0rem" }}
                justifyContent="space-between"
            >
                <ContainerRow
                    isHeader
                >
                    <Flex width="100%" >
                        <Text bold>Mã cổ phiếu</Text>
                    </Flex>
                    <Flex width="100%" >
                        <Text bold>Tên công ty</Text>
                    </Flex>
                    <Flex width="100%" justifyContent={["space-between", , "flex-end"]} paddingRight={["0rem", , "2rem"]}>
                        <Text bold>Mệnh giá</Text>
                    </Flex>
                    <Flex width="100%" justifyContent={["space-between", , "center"]} paddingRight={["0rem", , "0.5rem"]}>
                        <Text bold>Địa chỉ contract</Text>
                    </Flex>
                    <Flex width="100%" justifyContent={["space-between", , "flex-end"]} paddingRight={["0rem", , "1rem"]}>
                        <TextWithTooltip
                            title="TSLPH"
                            tooltipText="Tổng số lượng phát hành"
                        />
                    </Flex>
                    <Flex width="100%" justifyContent={["space-between", , "flex-end"]} paddingRight={["0rem", , "1rem"]}>
                        <TextWithTooltip
                            title="TGTCP"
                            tooltipText="Tổng giá trị cổ phiếu"
                        />
                        <Text bold></Text>
                    </Flex>
                    <Flex width="100%" />
                </ContainerRow>
                {currentItems?.length === 0 ?
                    <Flex
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                        height="300px"
                    >
                        <Image
                            height={115}
                            width={115}
                            src="/images/logo/no_data.png"
                            alt="no data"
                        />
                    </Flex>
                    :
                    <Flex
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                        height="auto"
                        flexDirection="column"
                        style={{ gap: isMobile ? "1rem" : "0rem" }}
                    >
                        {currentItems?.map((dt) =>
                            <Row item={dt} account={account} chainId={chainId} />
                        )}
                    </Flex>
                }
            </Flex>
            <Flex paddingTop="3rem" width="100%">
                {currentItems?.length > 0 &&
                    <Paginate
                        total={pageCount}
                        pageSize={ITEMSPERPAGE}
                        pageActive={pageActiveManage}
                        handlePageClick={handlePageClick}
                    />
                }
            </Flex>
        </Flex>
    )
}
export default Manage