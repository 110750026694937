import { useTranslation } from "@pancakeswap/localization"
import { useToast } from "@pancakeswap/uikit"
import { ToastDescriptionWithTx } from "components/Toast"
import { useCallWithMarketGasPrice } from "hooks/useCallWithMarketGasPrice"
import { useCallback, useState } from "react"

export const useAddStocksToMarketplace = () => {
    const { toastSuccess, toastError } = useToast()
    const { callWithMarketGasPrice } = useCallWithMarketGasPrice()
    const { t } = useTranslation()
    const [ pendingTx, setPendingTx ] = useState(false)
    
    const handleAddStocksToMarketplacer = useCallback(async (contract, stocksAddress) => {
        setPendingTx(true)
        try {
            const tx = await callWithMarketGasPrice(contract, 'addTradableStock', [stocksAddress])
            const receipt = await tx.wait()
    
            if (receipt.status) {
                toastSuccess(
                    "Thêm vào whitelist thành công!",
                    <ToastDescriptionWithTx txHash={receipt.transactionHash}/>
                )
            } else {
                // user rejected tx or didn't go thru
                toastError(t('Thất bại!'), t('Đã xảy ra lỗi, vui lòng thử lại!'))
            }
        } catch (e) {
            console.error(e)
            toastError(t('Thất bại!'), t('Đã xảy ra lỗi, vui lòng thử lại!'))
        } finally {
            setPendingTx(false) 
        }
    }, [callWithMarketGasPrice, toastSuccess, t, toastError])  
    return { handleAddStocksToMarketplacer, pendingTx }
  }
  