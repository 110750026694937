import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 21 21" {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6131 3.673C13.5894 2.69669 15.1723 2.69669 16.1486 3.673L17.3271 4.85151C18.3034 5.82782 18.3034 7.41073 17.3271 8.38704L8.66669 17.0475C8.55035 17.1638 8.40219 17.2431 8.24086 17.2754L3.82145 18.1592C3.23834 18.2759 2.72424 17.7618 2.84086 17.1787L3.72475 12.7592C3.75701 12.5979 3.83631 12.4498 3.95264 12.3334L12.6131 3.673ZM14.9701 4.85151L16.1486 6.03002C16.474 6.35546 16.474 6.8831 16.1486 7.20853L14.9701 8.38705L12.6131 6.03002L13.7916 4.85151C14.117 4.52607 14.6447 4.52607 14.9701 4.85151ZM11.4346 7.20853L5.30957 13.3335L4.72031 16.2798L7.66659 15.6905L13.7916 9.56556L11.4346 7.20853Z" fill="#6F767E"/>
        </Svg>
    );
};

export default Icon;
