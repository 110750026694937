import { Flex, Text, useTooltip } from "@pancakeswap/uikit";
import { IconWarning } from "icons";
import React from "react";

interface Props {
    title:string,
    tooltipText: string
}
const TextWithTooltip:React.FC<Props> = ({
    title, tooltipText
}) => {
    const { targetRef, tooltip, tooltipVisible } = useTooltip(
        tooltipText,
        {
            placement: 'top',
        },
    )
    return (
        <Flex alignItems="center" style={{gap:"12px"}}>
            <Text bold>{title}</Text>
            <Flex  ref={targetRef}>
                <IconWarning/>
            </Flex>
            {tooltipVisible && tooltip}
        </Flex>
    )
}

export default TextWithTooltip