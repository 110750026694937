
import { bscTestnetTokens } from "@pancakeswap/tokens";
import { StockType } from "./type";

const stocksSupport: StockType[] = [
    {
        id: 27,
        contract: bscTestnetTokens.Demo28
    },
    {
        id: 26,
        contract: bscTestnetTokens.Demo27
    },
    {
        id: 25,
        contract: bscTestnetTokens.Demo26
    },
    {
        id: 24,
        contract: bscTestnetTokens.Demo25
    },
    {
        id: 23,
        contract: bscTestnetTokens.Demo24
    },
    {
        id: 22,
        contract: bscTestnetTokens.Demo23
    },
    {
        id: 21,
        contract: bscTestnetTokens.Demo22
    },
    {
        id: 20,
        contract: bscTestnetTokens.Demo21
    },
    {
        id: 19,
        contract: bscTestnetTokens.Demo20
    },
    {
        id: 18,
        contract: bscTestnetTokens.Demo19
    },
    {
        id: 17,
        contract: bscTestnetTokens.Demo18
    },
    {
        id: 16,
        contract: bscTestnetTokens.Demo17
    },
    {
        id: 15,
        contract: bscTestnetTokens.Demo16
    },
    {
        id: 14,
        contract: bscTestnetTokens.Demo15
    },
    {
        id: 13,
        contract: bscTestnetTokens.Demo14
    },
    {
        id: 12,
        contract: bscTestnetTokens.Demo13
    },
    {
        id: 11,
        contract: bscTestnetTokens.Demo12
    },
    {
        id: 10,
        contract: bscTestnetTokens.Demo11
    },
    {
        id: 9,
        contract: bscTestnetTokens.Demo10
    },
    {
        id: 8,
        contract: bscTestnetTokens.Demo9
    },
    {
        id: 7,
        contract: bscTestnetTokens.Demo8
    },
    {
        id: 6,
        contract: bscTestnetTokens.Demo7
    },
    {
        id: 5,
        contract: bscTestnetTokens.Demo6
    },
    {
        id: 4,
        contract: bscTestnetTokens.Demo5
    },
    {
        id: 3,
        contract: bscTestnetTokens.Demo4
    },
    {
        id: 2,
        contract: bscTestnetTokens.Demo3
    },  
    {
        id: 1,
        contract: bscTestnetTokens.Demo2
    },  
    {
        id: 0,
        contract: bscTestnetTokens.Demo1
    }, 
   

].map((p) => ({ ...p, contract: p.contract.serialize }))

export default stocksSupport;