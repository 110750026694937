import PageFullWidth from 'components/Layout/PageFullWidth'
import { ButtonMenu, ButtonMenuItem, Flex, Heading } from '@pancakeswap/uikit'
import Container from 'components/Layout/Container'
import { GetDataHome, HomeTab, UseCoreManage } from 'state/home'
import { fetchActiveTab} from 'state/home/actions'
import { useAppDispatch } from 'state'
import Release from './Release'
import Manage from './Manage'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

const Home = () => {
    const { chainId, account } = useActiveWeb3React()
    UseCoreManage(chainId, account)
    const { activeTab, tradingType, releaseType, isLoading, dataStocks, pageActiveManage, isOwnerMarketplace } = GetDataHome()
    const dispatch = useAppDispatch()
   
    return (
        <PageFullWidth>
            <Container width="100%">
                <Flex width="100%" justifyContent="center" mt="2rem">
                    <Heading scale='xl'>
                        { activeTab === HomeTab?.MANAGE ?
                            "QUẢN LÝ CỔ PHIẾU"
                        :
                            "PHÁT HÀNH CỔ PHIẾU"
                        }
                    </Heading>
                </Flex>
                <Flex width="100%" mt="2rem">
                    <ButtonMenu activeIndex={activeTab} onItemClick={(newIndex:number) => dispatch(fetchActiveTab({activeTab: newIndex})) } >
                        <ButtonMenuItem>Quản lý</ButtonMenuItem>
                        <ButtonMenuItem>Phát hành</ButtonMenuItem>
                    </ButtonMenu>
                </Flex>
                <Flex width="100%" mt="2rem">
                    { activeTab === HomeTab?.MANAGE ?
                        <Manage
                            tradingType={tradingType}
                            isLoading={isLoading}
                            dataStocks={dataStocks}
                            pageActiveManage={pageActiveManage}
                            account={account}
                            chainId={chainId}
                        />
                    :
                        <Release releaseType={releaseType} isOwnerMarketplace={isOwnerMarketplace}/>
                    }
                </Flex>
            </Container>
        </PageFullWidth>
    )
}

export default Home
 