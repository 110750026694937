import { SUPPORTED_PAGE } from 'config'
import { AuthContext } from 'contexts/AuthContext'
import { useContext } from 'react'
import AuthPage from 'views/Auth'
import Home from 'views/Home'

const IndexPage = () => {
    const { isLoggedIn } = useContext(AuthContext)
    return isLoggedIn ? <Home/> : <AuthPage/>
}

IndexPage.chains = SUPPORTED_PAGE

export default IndexPage
