
import { useToast } from "@pancakeswap/uikit";

export const useUserRejected = () => {
    const { toastError } = useToast()

    const handleRejected = (error) => {
        const stringifyError = JSON.stringify(error);
        const parseError = JSON.parse(stringifyError);
        console.log(parseError)
        if (parseError?.code === "ACTION_REJECTED") toastError("Người dùng đã từ chối thực hiện giao dịch");
        else toastError("Đã có lỗi xảy ra", "Xác nhận giao dịch và đảm bảo rằng bạn đủ tiền trong ví để xử lý giao dịch !")
    };

    return { handleRejected }
};