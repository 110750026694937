import styled from "styled-components";
import { CheckboxProps, scales } from "./types";

const getScale = ({ scale }: CheckboxProps) => {
    switch (scale) {
        case scales.SM:
            return "24px";
        case scales.MD:
        default:
            return "32px";
    }
};

const CheckboxV2 = styled.input.attrs({ type: "checkbox" })<CheckboxProps>`
    appearance: none;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    height: ${getScale};
    width: ${getScale};
    vertical-align: middle;
    transition: background-color 0.2s ease-in-out;
    border: 0;
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.colors.textDisabled};
    align-items: center;
    justify-content: center;
    &:after {
        content: "";
        width: 50%;
        height: 50%;
        margin: auto;
        transition: border-color 0.2s ease-in-out;
        border-radius: 50%;
    }
    &:checked {
        background-color: ${({ theme }) => theme.colors.primary};
        border: none;
        &:after {
            background-color: ${({ theme }) => theme.colors.backgroundAlt2};
        }
    }

    &:disabled {
        cursor: default;
        background-color: ${({ theme }) => theme.colors.text};
        opacity: 0.6;
        &:after {
            content: "";
            width: 50%;
            height: 25%;
            margin: auto;
            transform: rotate(-50deg);
            transition: border-color 0.2s ease-in-out;
            border-color: ${({ theme }) => theme.colors.background};
        }
    }
`;

CheckboxV2.defaultProps = {
    scale: scales.MD,
    checked: false,
};

export default CheckboxV2;
