import { Flex, Text, useTooltip } from "@pancakeswap/uikit";
import { IconWarning } from "icons";
import React, { ReactNode } from "react";

interface Props {
    title: ReactNode,
    tooltipText: ReactNode| string,
}
const TextWithTooltipV2: React.FC<Props> = ({
    title, tooltipText
}) => {
    const { targetRef, tooltip, tooltipVisible } = useTooltip(
        tooltipText,
        {
            placement: 'top',

        },

    )
    return (
        <Flex width="100%" alignItems="center" zIndex={20}>
            <Flex justifyContent="flex-end" alignItems="center" width="100%" ref={targetRef}>
                {title}
            </Flex>
            {tooltipVisible && tooltip}
        </Flex>
    )
}

export default TextWithTooltipV2