import React, { useEffect, useState } from "react";
import { AutoRenewIcon, Button, CheckboxV2, CopyIcon, Flex, Input, InputGroup, Text, useToast } from "@pancakeswap/uikit";
import { CopyButton } from "components/CopyButton";
import { ToastDescriptionWithTx } from "components/Toast";
import { ContractFactory } from "ethers";
import { useContractMarketplace } from "hooks/useContract";
import { useUserRejected } from 'hooks/useUserRejected';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch } from "state";
import { ReleaseType } from "state/home";
import { fetchReleaseType } from "state/home/actions";
import { getAddress } from "utils/addressHelpers";
import { useSigner } from "wagmi";
import { isAddress } from 'web3-validator';
import { useAddStocksToMarketplace } from "./hook/useAddStocksToMarketplace";
import { getDataStocksOnWhitelist } from "state/home/fetchDataStocks";
import { bytecode } from 'config/constants/bytecode';
import stock from "config/abi/stocks.json";
import styled from "styled-components";
import NumberFormat from "react-number-format";
import contracts from "config/constants/contracts";
import stocksSupport from "config/constants/stocks/97";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import useTheme from "hooks/useTheme";
import { isDesktop, isMobile } from "react-device-detect";
import UploadImageMobile from "views/Edit/UploadImageMobile";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Radio } from "antd/lib";
import UploadImage from "views/Edit/UploadImage";
import { Tooltip } from "../components/style";

interface Props {
    releaseType: number,
    isOwnerMarketplace: boolean
}
const Release: React.FC<Props> = ({
    releaseType, isOwnerMarketplace
}) => {
    const { theme } = useTheme();
    const { chainId, account } = useActiveWeb3React()
    const [fillCidLink, setFillCidLink] = useState("");
    const dispatch = useAppDispatch()
    const { data: signer } = useSigner()
    const { toastError, toastSuccess } = useToast()
    const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false);
    const { handleRejected } = useUserRejected();
    const [pendingDeploy, setPendingDeploy] = useState(false)
    const [contractAddress, setContractAddress] = useState("");
    const marketplaceAddress = getAddress(contracts?.marketplace, chainId)
    const contract = useContractMarketplace(marketplaceAddress)
    const [currenTime, setCurrentTime] = useState(0)
    const { handleAddStocksToMarketplacer, pendingTx } = useAddStocksToMarketplace()
    const { isWhitelist } = getDataStocksOnWhitelist(contractAddress, marketplaceAddress, chainId, currenTime)
    const [errorImg, setErrorImg] = useState(false);

    const handleAddWhitelist = async () => {
        await handleAddStocksToMarketplacer(contract, contractAddress)
        setCurrentTime(Date.now())
    }

    const handleCoppy = (address: string) => {
        if (navigator.clipboard && navigator.permissions) {
            navigator.clipboard.writeText(address).then(() => displayTooltipCode())
        } else if (document.queryCommandSupported('copy')) {
            const ele = document.createElement('textarea')
            ele.value = address
            document.body.appendChild(ele)
            ele.select()
            document.execCommand('copy')
            document.body.removeChild(ele)
            displayTooltipCode()
        }
    }

    function displayTooltipCode() {
        setIsTooltipDisplayed(true)
        setTimeout(() => {
            setIsTooltipDisplayed(false)
        }, 3000)
    }

    const isExist = (code: string) => {
        const exist = stocksSupport.filter(item => item?.contract?.symbol.toString() === code)
        return exist.length === 0;
    }
    const isAddressValid = (walletAddress: string) => walletAddress.length > 0 && isAddress(walletAddress);
    const validationSchema = Yup.object().shape({
        code: Yup
            .string()
            .required("Vui lòng nhập mã cổ phiếu")
            .test("type", "Mã cổ phiếu đã tồn tại", isExist),
        companyName: Yup
            .string()
            .required("Vui lòng nhập tên công ty"),
        walletAddress: Yup
            .string()
            .required("Vui lòng nhập địa chỉ ví")
            .test("type", "Địa chỉ ví không hợp lệ", isAddressValid),
        price: Yup
            .string()
            .required("Vui lòng nhập mệnh giá"),
        radio: Yup.number(),
    });
    useEffect(() => {
        setValue("code", "")
        setValue("companyName", "")
        setValue("walletAddress", "")
        setValue("price", "")
        setValue("radio", 0)
        setFillCidLink("");
        setContractAddress("");
    }, []);

    const {
        register,
        reset,
        resetField,
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        setValue,
        watch
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data) => {
        console.log("Data release submit",
            fillCidLink,
            Number(data?.radio) === 1 ? true : false,
            data?.code,
            data?.companyName,
            data?.walletAddress,
            Number(data?.price.replace(/,/g, ""))
        );
        setPendingDeploy(!false)
        try {
            const factory = new ContractFactory(stock, bytecode, signer);
            const contract = await factory.deploy(
                fillCidLink,
                Number(data?.radio) === 1 ? true : false,
                data?.code,
                data?.companyName,
                data?.walletAddress,
                Number(data?.price.replace(/,/g, ""))
            )
            await contract.deployed();
            setContractAddress(contract?.address);
            toastSuccess("Phát hành thành công",
                <Flex flexDirection="column" justifyContent="flex-start">
                    <Text><span style={{ color: "#777E90", fontWeight: 700 }}>Mã cổ phiếu: </span>{data?.code}</Text>
                    <ToastDescriptionWithTx txHash={contract?.deployTransaction.hash} />
                </Flex>
            );
            reset();
            setValue("code", "")
            setValue("companyName", "")
            setValue("walletAddress", "")
            setValue("price", "")
            setValue("radio", 0)
            setFillCidLink("");
            setFillCidLink("");
            dispatch(fetchReleaseType({ releaseType: ReleaseType.AUTO }))
        } catch (e) {
            handleRejected(e);
        } finally {
            setPendingDeploy(false);
        }
    };
    useEffect(() => {
        dispatch(fetchReleaseType({ releaseType: ReleaseType.AUTO }))
    }, []);

    return (
        <Flex width="100%" padding={["0.5rem", , , "2rem"]} flexDirection="column" style={{ gap: "1rem", borderRadius: "20px", backgroundColor: "#F8F8F8" }}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                    width: "100%",
                    display: "flex"
                }}
            >
                <Flex width="100%" flexDirection="column" style={{ gap: "1rem" }}>
                    <Flex alignItems="center" justifyContent="space-between" height="100%" flexDirection={["column", "column", "row", "row"]} style={{ gap: "2rem" }}>
                        <Flex width={["100%", "100%", "100%", "50%"]} height="100%" alignItems="center" justifyContent="space-between" flexDirection="column" style={{ gap: "1rem" }}>
                            {isDesktop ?
                                <UploadImage
                                    handleCidLink={(newCidLink) => setFillCidLink(newCidLink)}
                                    defaultImage={""}
                                    cidLink={fillCidLink}
                                    errorImg={errorImg}
                                    handleErrorImg={(newError) => setErrorImg(newError)}
                                    deleteImg={null}
                                    handleDelete={null}
                                />
                                :
                                <UploadImageMobile />
                            }
                        </Flex>
                        {
                            isMobile &&
                            <Flex width="100%" flexDirection="column" justifyContent="space-between" style={{ gap: "12px" }}>
                                <Text color="var(--neutral-05, #33383F);" bold fontSize="18px">Mã cổ phiếu</Text>
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    borderRadius="12px"
                                    height="100%"
                                    alignItems="flex-start"
                                    flexDirection="column"
                                    style={{ gap: "12px" }}
                                >
                                    <Controller
                                        control={control}
                                        name="code"
                                        render={({ field }) => (
                                            <CsInputFormat
                                                {...field}
                                                placeholder={"Nhập mã cổ phiếu"}
                                                isError={errors.code ? true : false}
                                                onFocus={() => setErrorImg(false)}
                                            />
                                        )}
                                    />
                                    {errors.code && errors.code.type === "validate" && <Text color="red">Mã cổ phiếu đã tồn tại</Text>}
                                    {errors.code && <Text color="red">{errors.code.message.toString()}</Text>}
                                </Flex>
                            </Flex>
                        }
                        <Flex width={["100%", "100%", "100%", "50%"]} height="100%" alignItems="center" justifyContent="space-between" flexDirection="column" style={{ gap: "1rem" }}>
                            <Flex width="100%" flexDirection="column" justifyContent="space-between" style={{ gap: "12px" }}>
                                <Text bold color="var(--neutral-05, #33383F);" fontSize="18px">Tên công ty</Text>
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    borderRadius="12px"
                                    height="100%"
                                    alignItems="flex-start"
                                    flexDirection="column"
                                    style={{ gap: "12px" }}
                                >
                                    <Controller
                                        control={control}
                                        name="companyName"
                                        render={({ field }) => (
                                            <CsInputFormat
                                                {...field}
                                                placeholder={"Nhập tên công ty"}
                                                isError={errors.companyName ? true : false}
                                                onFocus={() => setErrorImg(false)}
                                            />
                                        )}
                                    />
                                    {errors.companyName && <Text color="red">{errors.companyName.message}</Text>}
                                </Flex>
                            </Flex>
                            <Flex width="100%" flexDirection="column" justifyContent="space-between" style={{ gap: "12px" }}>
                                <Text bold color="var(--neutral-05, #33383F);" fontSize="18px">Địa chỉ ví</Text>
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    borderRadius="12px"
                                    height="100%"
                                    alignItems="flex-start"
                                    flexDirection="column"
                                    style={{ gap: "12px" }}
                                >
                                    <Controller
                                        control={control}
                                        name="walletAddress"
                                        render={({ field }) => (
                                            <InputGroup
                                                endIcon={<CopyButton text={field.value} tooltipMessage={"Coppied"} tooltipRight={40} tooltipTop={0} />}
                                            >
                                                <CsInputFormat
                                                    {...field}
                                                    placeholder={"Nhập địa chỉ ví"}
                                                    isError={errors.walletAddress ? true : false}
                                                    onFocus={() => setErrorImg(false)}
                                                />
                                            </InputGroup>
                                        )}
                                    />
                                    {errors.walletAddress && <Text color="red">{errors.walletAddress.message.toString()}</Text>}
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex alignItems="center" justifyContent="space-between" height="100%" flexDirection={["column", "column", "row", "row"]} style={{ gap: "2rem" }}>
                        <Flex width={["100%", "100%", "100%", "50%"]} height="100%" alignItems="center" justifyContent="space-between" flexDirection="column" style={{ gap: "1.5rem" }}>
                            {
                                isDesktop &&
                                <Flex width="100%" flexDirection="column" justifyContent="space-between" style={{ gap: "12px" }}>
                                    <Text color="var(--neutral-05, #33383F);" bold fontSize="18px">Mã cổ phiếu</Text>
                                    <Flex
                                        width="100%"
                                        justifyContent="space-between"
                                        borderRadius="12px"
                                        height="100%"
                                        alignItems="flex-start"
                                        flexDirection="column"
                                        style={{ gap: "12px" }}
                                    >
                                        <Controller
                                            control={control}
                                            name="code"
                                            render={({ field }) => (
                                                <CsInputFormat
                                                    {...field}
                                                    placeholder={"Nhập mã cổ phiếu"}
                                                    isError={errors.code ? true : false}
                                                    onFocus={() => setErrorImg(false)}
                                                />
                                            )}
                                        />
                                        {errors.code && errors.code.type === "validate" && <Text color="red">Mã cổ phiếu đã tồn tại</Text>}
                                        {errors.code && <Text color="red">{errors.code.message.toString()}</Text>}
                                    </Flex>
                                </Flex>
                            }

                        </Flex>
                        <Flex width={["100%", "100%", "100%", "50%"]} height="100%" justifyContent="space-between" flexDirection="column" style={{ gap: "1.5rem" }}>
                            <Flex width="100%" flexDirection="column" justifyContent="space-between" style={{ gap: "12px" }}>
                                <Text bold color="var(--neutral-05, #33383F);" fontSize="18px">Mệnh giá</Text>
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    borderRadius="12px"
                                    height="100%"
                                    alignItems="flex-start"
                                    flexDirection="column"
                                    style={{ gap: "12px" }}
                                >
                                    <InputGroup
                                        endIcon={
                                            <Flex padding="8px 16px 6px 16px" background={theme.colors.green} borderRadius="6px">
                                                <Text color="white">VND</Text>
                                            </Flex>
                                        }
                                    >
                                        <Controller
                                            control={control}
                                            name="price"
                                            render={({ field }) => (
                                                <CsNumericFormat
                                                    {...field}
                                                    decimalScale={0}
                                                    allowNegative={false}
                                                    allowLeadingZeros={false}
                                                    thousandSeparator={!false}
                                                    placeholder={"0"}
                                                    isError={errors.price ? true : false}
                                                    onFocus={() => setErrorImg(false)}
                                                />
                                            )}
                                        />
                                    </InputGroup>
                                    {errors.price && <Text color="red">{errors.price.message.toString()}</Text>}
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex width="100%" justifyContent="flex-start" height="100%" flexDirection="column" style={{ gap: "2rem" }}>
                        <Text bold color="var(--neutral-05, #33383F);" fontSize="18px">Hình thức phê duyệt</Text>
                        <Flex alignItems="center" style={{ gap: "2rem" }}>
                            <Flex alignItems="center" style={{ gap: "1rem" }}>
                                <Controller
                                    name="radio"
                                    control={control}
                                    defaultValue={0}
                                    render={({ field }) => {
                                        return (
                                            <CsRadio {...field} onFocus={() => setErrorImg(false)}>
                                                <Radio value={ReleaseType.AUTO}><Text fontSize="14px" bold>Tự động</Text></Radio>
                                                <Radio value={ReleaseType.BYHAND}><Text fontSize="14px" bold>Thủ công</Text></Radio>
                                            </CsRadio>
                                        )
                                    }}
                                />
                            </Flex>
                        </Flex>

                        {contractAddress?.length > 0 &&
                            <Flex >
                                <Flex style={{ gap: "14px" }} alignItems="center" width={["100%", , "600px"]} justifyContent={["space-between", , "flex-start"]} flexWrap="wrap">
                                    <Text color='#9395A4'>Đã phát hành</Text>
                                    <Text bold>{contractAddress}</Text>
                                    <CopyIcon style={{ cursor: "pointer" }} onClick={() => handleCoppy(contractAddress)} />
                                    <Tooltip isTooltipDisplayed={isTooltipDisplayed}>Copied</Tooltip>
                                </Flex>
                            </Flex>
                        }
                        <Button
                            padding="0 12px"
                            width={["100%", , "200px"]}
                            disabled={
                                pendingDeploy
                                || watch("code")?.length === 0
                                || watch("companyName")?.length === 0
                                || watch("walletAddress")?.length === 0
                                || watch("price")?.length === 0
                                || Object.keys(errors)?.length > 0
                            }
                            endIcon={pendingDeploy ? <AutoRenewIcon spin color='#9395A4' /> : ""}
                        >
                            Phát hành
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Flex>
    )
}
export default Release;

const CsInputFormat = styled(Input) <{ isError: boolean }>`
    outline: none;
    font-size: 16px;
    border-radius: 12px;
    height:56px;
    border: ${({ isError }) => isError ? '2px solid red' : '2px solid #E1E8E5'};
    background: var(--neutral-00, #FFF);
    &:focus-visible {
        outline: none;  
        border: ${({ isError }) => isError ? '2px solid red' : '2px solid rgba(53, 93, 255, 0.35)'};
    }
    ::placeholder {
        color:${({ theme }) => theme.colors.textSubtle};
        opacity: 1;
    }
`
const CsNumericFormat = styled(NumberFormat) <{ isError: boolean }>`
    background-color: transparent;
    border-radius: 12px;
    color: ${({ theme }) => theme.colors.text};
    display: block;
    font-size: 14px;
    outline: 0;
    padding: 0 16px;
    height:56px;
    width: 100%;
    border: ${({ isError }) => isError ? '2px solid red' : '2px solid #E1E8E5'};
    background: var(--neutral-00, #FFF);
    &::placeholder {
        color: ${({ theme }) => theme.colors.textSubtle};
    }
    &:focus-visible {
        outline: none;
        border: 2px solid rgba(53, 93, 255, 0.35);
    }
    &:disabled {
        background-color: ${({ theme }) => theme.colors.backgroundDisabled};
        box-shadow: none;
        color: ${({ theme }) => theme.colors.textDisabled};
        cursor: not-allowed;
    }
`
const CsRadio = styled(Radio.Group)`
    .ant-radio-inner{
        width:24px;
        height:24px;
        border-color: #000000 !important ;
        background-color:transparent !important;
    }
    .ant-radio-checked .ant-radio-inner {
        border-color: #355DFF !important ;
        border-width:6px;
      }
    .ant-radio-checked {
        background-color: transparent !important;
    }
`