import React from "react";
import { StockItem } from "state/home/type";
import { ContainerCard } from "../components/style";
import { TotalPriceIcon, TotalReleaseIcon, TotalStocksIcon } from "@pancakeswap/uikit";
import Card from "../components/Card";
import BigNumber from "bignumber.js";

interface Props {
    data: StockItem[],
    isLoading: boolean
}
const WrappedCard:React.FC<Props> = ({
    data, isLoading
}) => {
    console.log("aksjkalsjl",data);
    
    const countTotalIssue = data.reduce(function(acc, curr) {
        acc += new BigNumber(curr?.totalReleaseStocks).toNumber();
        return acc;
    }, 0)

    const countTotalValueOfStocks = data.reduce(function(acc, curr) {
        acc += new BigNumber(curr?.totalValueOfStocks).toNumber();
        return acc;
    }, 0)
    
    return (
        <ContainerCard>
            <Card
                icon={<TotalStocksIcon width="42px"/>}
                title="Tổng cổ phiếu phát hành"
                value={countTotalIssue}
                unit="Cổ phiếu"
                isLoading={isLoading}
            /> 
            <Card
                icon={<TotalReleaseIcon width="42px"/>}
                title="Tổng đơn vị phân phối"
                value={data?.length}
                isLoading={isLoading}
            /> 
            <Card
                icon={<TotalPriceIcon width="42px"/>}
                title="Tổng tiền cổ phiếu đã phát hành"
                value={countTotalValueOfStocks}
                unit="VND"
                isLoading={isLoading}
            />     
        </ContainerCard>
    )
}

export default WrappedCard